import axios from 'axios';

const baseUrlIntegrity = process.env.VUE_APP_BASE_URL_INTEGRITY;

const baseApi = axios.create({
  baseURL: baseUrlIntegrity,
});

const baseUrlQsmart = process.env.VUE_APP_BASE_URL_QSMART;

const qsmartApi = axios.create({
  baseURL: baseUrlQsmart,
});

export { baseApi, qsmartApi };
