import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'default',
    component: () => import(/* webpackChunkName: "default" */ '@/views/default/Main.vue'),
  },
  {
    path: '/demo-ciiu',
    name: 'demo-ciiu',
    component: () => import(/* webpackChunkName: "demo-ciiu" */ '@/views/ciiu/Main.vue'),
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import(/* webpackChunkName: "personal" */ '@/views/personal/Main.vue'),
  },
  {
    path: '/fast',
    name: 'fast',
    component: () => import(/* webpackChunkName: "fast" */ '@/views/fast/Main.vue'),
    alias: ['/camaras-de-comercio', '/facturas'],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
