import service from '@/services/SIRsic_ocr_dcli';

export default {
  namespaced: true,

  state: {
    element_selected: undefined,
    elements: [],
  },

  mutations: {
    SET_ELEMENT_SELECTED(state, value) {
      state.element_selected = value;
      if (value) {
        sessionStorage.setItem('ruleId', value);
      } else {
        sessionStorage.removeItem('ruleId');
      }
    },

    SET_ELEMENTS(state, value) {
      if (value) {
        state.elements = value;
        sessionStorage.setItem('ruleElements', JSON.stringify(value));
      } else {
        state.elements = [];
        sessionStorage.removeItem('ruleElements');
      }
    },
  },

  actions: {
    async read({
      commit, dispatch, rootState, state,
    }, data) {
      // eslint-disable-next-line camelcase
      const { piccia__nit = '' } = data;

      const payload = {
        dsSIRsic_ocr_dcli: {
          eeDatos: [
            {
              picfiid: rootState.token.picfiid,
              picusrcod: rootState.token.picusrcod,
            },
          ],
          eeSIRsic_ocr_dcli: [
            {
              piccia__nit,
            },
          ],
        },
      };

      await service
        .read(payload)
        .then((response) => {
          if (response.data.dsSIRsic_ocr_dcli.eeEstados[0].Returnid === 0) {
            commit(
              'SET_ELEMENTS',
              response.data.dsSIRsic_ocr_dcli.eesic_ocr_dcli,
            );

            if (state.elements.length > 0) {
              const index = state.elements.findIndex(
                (element) => element.ocr_doc_id
                  === parseInt(process.env.VUE_APP_OCR_DOC_ID_DEFAULT, 10),
              );

              if (index === -1) {
                commit('SET_ELEMENT_SELECTED', state.elements[0].ocr_doc_id);
              } else {
                commit(
                  'SET_ELEMENT_SELECTED',
                  state.elements[index].ocr_doc_id,
                );
              }
            } else {
              commit('SET_ELEMENT_SELECTED', undefined);
              commit('SET_ELEMENTS', []);
            }
          } else {
            commit('SET_ELEMENT_SELECTED', undefined);
            commit('SET_ELEMENTS', []);
            dispatch(
              'messaging/addMessage',
              {
                text: response.data.dsSIRsic_ocr_dcli.eeEstados[0].Estado,
                display: true,
              },
              {
                root: true,
              },
            );
          }
        })
        .catch((error) => {
          dispatch(
            'messaging/addMessage',
            {
              text: error.toString(),
              display: true,
            },
            {
              root: true,
            },
          );
        });
    },

    toggleElementSelected({ commit }, value) {
      commit('SET_ELEMENT_SELECTED', value);
    },
  },

  getters: {
    getRuleNameSelected(state) {
      let ruleName = null;

      const id = state.element_selected;

      if (id) {
        const rule = state.elements.find((e) => e.ocr_doc_id === id);

        ruleName = rule.ocr_doc_nom;
      }

      return ruleName;
    },
  },
};
