import service from '@/services/SIRqsmartAPINoAuth';
import QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';

export default {
  namespaced: true,

  state: {
    categoriesCount: 0,
    categories: ['comida', 'entretenimiento', 'servicios', 'otros'],

    error: {},

    invoicesCount: 0,
    invoices: [],

    state: undefined,

    states: Object.freeze({
      error: 'error',
      loading: 'loading',
      success: 'success',
    }),
  },

  mutations: {
    INCREMENT_INVOICES_COUNT(state) {
      state.invoicesCount += 1;
    },

    SET_CATEGORIES_COUNT(state, value) {
      state.categoriesCount = value;
    },

    SET_INVOICES(state, invoice) {
      const arregloTotal = invoice.General.find(
        (node) => node.eId === 'arregloTotal',
      );
      let amount = 0;

      if (arregloTotal) {
        amount = arregloTotal.eResults?.[0].eValues?.[0].eValue?.[0];
        const { length } = amount;

        if (amount[length - 3] === '.' || amount[length - 3] === ',') {
          amount = amount.substring(0, length - 3);
        }

        if (amount.includes('.')) {
          amount = amount.replaceAll('.', '');
        }

        if (amount.includes(',')) {
          amount = amount.replaceAll(',', '');
        }

        arregloTotal.eResults[0].eValues[0].eValue[0] = Number(amount);
      }

      state.invoices.push({
        ...invoice,
        amount: Number(amount),
      });
    },

    SET_ERROR(state, value) {
      state.error = value;
    },

    SET_STATE(state, value) {
      state.state = value;
    },
  },

  actions: {
    async create({ commit, state }, data) {
      const payload = {
        dsData: {
          eeDatos: [
            {
              picfiid: process.env.VUE_APP_USER_PASS,
              picusrcod: process.env.VUE_APP_USER_ID,
            },
          ],
          eeSIRqsmart: [
            {
              documentBase64: data.documentBase64,
              documentType: data.documentType,
              fileType: data.fileType,
            },
          ],
        },
      };

      commit('SET_STATE', state.states.loading);
      commit('SET_ERROR', {});
      service
        .create(payload)
        .then((response) => {
          if (response.data.dsData.eeEstados[0].Returnid === 0) {
            const invoice = JSON.parse(JSON.stringify(response.data.dsData));

            invoice.amount = undefined;

            invoice.category = state.categories[state.categoriesCount];
            if (state.categoriesCount === 3) {
              commit('SET_CATEGORIES_COUNT', 0);
            } else {
              commit('SET_CATEGORIES_COUNT', state.categoriesCount + 1);
            }
            // invoice.category = state.categories[Math.floor(Math.random() * 4)];

            invoice.id = state.invoicesCount;
            invoice.image = data.image;
            invoice.qrcode = undefined;
            invoice.uuid = undefined;

            if (state.invoicesCount % 2 === 0) {
              invoice.uuid = uuidv4();
              QRCode.toDataURL(invoice.uuid).then((url) => {
                invoice.qrcode = url;
                commit('SET_INVOICES', invoice);
              });
            } else {
              commit('SET_INVOICES', invoice);
            }

            commit('INCREMENT_INVOICES_COUNT');
            commit('SET_STATE', state.states.success);
          } else {
            const notification = {
              code: response.data.dsData.eeEstados[0].Returnid,
              message: response.data.dsData.eeEstados[0].Estado,
              timestamp: Date.now(),
              type: 'error',
            };

            commit('SET_ERROR', notification);
            commit('SET_STATE', state.states.error);
          }
        })
        .catch((error) => {
          const notification = {
            code: error?.code || undefined,
            message: error?.message,
            timestamp: Date.now(),
            type: 'error',
          };
          commit('SET_ERROR', notification);
          commit('SET_STATE', state.states.error);
        });
    },
  },
};
