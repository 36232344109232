import service from '@/services/inicioJustToken';

export default {
  namespaced: true,

  state: {
    picfiid: undefined,
    picusrcod: undefined,
    username: undefined,
  },

  mutations: {
    SET_PICFIID(state, value) {
      state.picfiid = value;
      if (value) {
        sessionStorage.setItem('picfiid', value);
      } else {
        sessionStorage.removeItem('picfiid');
      }
    },

    SET_PICUSRCOD(state, value) {
      state.picusrcod = value;
      if (value) {
        sessionStorage.setItem('picusrcod', value);
      } else {
        sessionStorage.removeItem('picusrcod');
      }
    },

    SET_USERNAME(state, value) {
      state.username = value;
      if (value) {
        sessionStorage.setItem('username', value);
      } else {
        sessionStorage.removeItem('username');
      }
    },
  },

  actions: {
    async read({ commit, dispatch }, value) {
      const { picusrcod } = value;
      const { picusrpass } = value;

      const payload = {
        dslogin: {
          ttdatauser: [
            {
              picusrcod,
              picusrpass,
            },
          ],
        },
      };

      await service
        .read(payload)
        .then((response) => {
          if (response.data.dslogin.eeEstados[0].Returnid === 0) {
            commit('SET_USERNAME', picusrcod);
            commit('SET_PICFIID', response.data.dslogin.ttdatauser[0].picfiid);
            commit(
              'SET_PICUSRCOD',
              response.data.dslogin.ttdatauser[0].picusrcod,
            );
            commit('SET_MENU_DISABLED', false, { root: true });
          } else {
            commit('SET_USERNAME', undefined);
            commit('SET_PICFIID', undefined);
            commit('SET_PICUSRCOD', undefined);

            dispatch(
              'messaging/addMessage',
              {
                text: response.data.dslogin.eeEstados[0].Estado,
                display: true,
              },
              {
                root: true,
              },
            );
          }
        })
        .catch((error) => {
          dispatch(
            'messaging/addMessage',
            {
              text: error.toString(),
              display: true,
            },
            {
              root: true,
            },
          );
        });
    },
  },
};
