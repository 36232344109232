export default {
  namespaced: true,

  state: {
    dialog: false,
    messages: [],
  },

  mutations: {
    ADD_MESSAGE(state, value) {
      state.messages.push(value);
    },

    DISPLAY_MESSAGE(state, value) {
      state.messages[value].display = false;
    },

    SET_DIALOG(state, value) {
      state.dialog = value;
    },
  },

  actions: {
    addMessage({ commit, dispatch }, value) {
      commit('ADD_MESSAGE', value);
      dispatch('closeDialog', true);
    },

    closeDialog({ commit }, value) {
      commit('SET_DIALOG', value);
    },

    displayMessage({ commit }, value) {
      commit('DISPLAY_MESSAGE', value);
    },
  },

  getters: {
    getMessages(state) {
      return state.messages;
    },
  },
};
