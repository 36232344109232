import service from '@/services/SIRsic_usr_nit';

export default {
  namespaced: true,

  state: {
    piccia__nit: undefined,
  },

  mutations: {
    SET_PICCIA__NIT(state, value) {
      state.piccia__nit = value;
    },
  },

  actions: {
    async read({ commit, dispatch, rootState }) {
      const payload = {
        dsSIRsic_usr_nit: {
          eeDatos: [
            {
              picfiid: rootState.token.picfiid,
              picusrcod: rootState.token.picusrcod,
            },
          ],
        },
      };

      await service
        .read(payload)
        .then((response) => {
          if (response.data.dsSIRsic_usr_nit.eeEstados[0].Returnid === 0) {
            commit(
              'SET_PICCIA__NIT',
              response.data.dsSIRsic_usr_nit.eeesic_user[0]['cia-nit'],
            );
          } else {
            commit('SET_PICCIA__NIT', undefined);

            dispatch(
              'messaging/addMessage',
              {
                text: response.data.dsSIRsic_usr_nit.eeEstados[0].Estado,
                display: true,
              },
              {
                root: true,
              },
            );
          }
        })
        .catch((error) => {
          dispatch(
            'messaging/addMessage',
            {
              text: error.toString(),
              display: true,
            },
            {
              root: true,
            },
          );
        });
    },
  },
};
