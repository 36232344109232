import service from '@/services/SIRqsmartAPI';

export default {
  namespaced: true,

  state: {
    elements: [],
    log: '99',
    version: '0',
  },

  mutations: {
    SET_ELEMENTS(state, value) {
      state.elements = [];

      if (value) {
        state.elements.push(value);
      }
    },

    SET_LOG(state, value) {
      if (value) {
        state.log = '0';
      } else {
        state.log = '99';
      }

      sessionStorage.setItem('log', state.log);
    },

    SET_VERSION(state, value) {
      if (value) {
        state.version = '1';
      } else {
        state.version = '0';
      }

      sessionStorage.setItem('version', state.version);
    },
  },

  actions: {
    async create({ commit, dispatch, rootState }, data) {
      dispatch('toggleLoading', true, { root: true });

      const payload = {
        dsData: {
          eeDatos: [
            {
              picfiid: rootState.token.picfiid,
              picusrcod: rootState.token.picusrcod,
            },
          ],
          eeSIRqsmart: [
            {
              documentBase64: data.documentBase64,
              documentType: data.documentType,
              fileType: data.fileType,
              qsmartVersion: rootState.SIRqsmartAPI.version,
              SinLog: rootState.SIRqsmartAPI.log,
            },
          ],
        },
      };

      await service
        .create(payload)
        .then((response) => {
          if (
            response.data.dsData !== undefined
            && response.data.dsData.eeEstados !== undefined
          ) {
            if (response.data.dsData.eeEstados[0].Returnid === 0) {
              dispatch('setqsmartTypeCurrent', data.context, { root: true });
              dispatch('toggleIconsVisible', true, { root: true });
              dispatch('toggleJson', response.data, { root: true });
              dispatch('toggleFileTypeSelected', data.documentType, {
                root: true,
              });
              dispatch('toggleCurrentPage', 1, { root: true });
            } else {
              if (response.data.dsData.eeEstados[0].Estado.toLocaleLowerCase() === 'token inválido' || response.data.dsData.eeEstados[0].Estado.toLocaleLowerCase() === 'token invalido') {
                commit('SIRsic_ocr_dcli/SET_ELEMENT_SELECTED', null, { root: true });
                commit('SIRsic_ocr_dcli/SET_ELEMENTS', null, { root: true });
                commit('token/SET_PICFIID', undefined, { root: true });
                commit('SET_JSON', undefined, { root: true });
                commit('SET_MENU_DISABLED', true, { root: true });
              }

              dispatch('toggleJson', undefined, { root: true });

              dispatch(
                'messaging/addMessage',
                {
                  text: response.data.dsData.eeEstados[0].Estado,
                  display: true,
                },
                {
                  root: true,
                },
              );
            }
          }
        })
        .catch((error) => {
          dispatch('toggleJson', undefined, { root: true });

          dispatch(
            'messaging/addMessage',
            {
              text: error.toString(),
              display: true,
            },
            {
              root: true,
            },
          );
        })
        .then(() => {
          dispatch('toggleLoading', false, { root: true });

          dispatch('toggleDialogUploadAudio', false, {
            root: true,
          });

          dispatch('toggleDialogUploadDocument', false, {
            root: true,
          });
        });
    },

    setElements({ commit }, value) {
      commit('SET_ELEMENTS', value);
    },
  },
};
