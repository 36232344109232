import service from '@/services/SICUDsic_ocr_con';

export default {
  namespaced: true,

  state: {
    data: {},

    error: {},

    state: undefined,

    states: Object.freeze({
      error: 'error',
      loading: 'loading',
      success: 'success',
    }),
  },

  mutations: {
    SET_DATA(state, value) {
      if (value) {
        state.data = value;
      } else {
        state.data = {};
      }
    },

    SET_ERROR(state, value) {
      state.error = value;
    },

    SET_STATE(state, value) {
      state.state = value;
    },
  },

  actions: {
    async create({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSICUDsic_ocr_con: {
            eeDatos: [
              {
                picfiid: process.env.VUE_APP_USER_PASS,
                picusrcod: process.env.VUE_APP_USER_ID,
              },
            ],
            eesic_ocr_con: [
              {
                ocr_con_com: '',
                ocr_con_emp: data.company,
                ocr_con_mail: data.email,
                ocr_con_nom: data.name,
                ocr_con_tel: data.phone,
                piindicador: 1,
              },
            ],
          },
        };

        commit('SET_STATE', state.states.loading);
        commit('SET_ERROR', {});
        service
          .create(payload)
          .then((response) => {
            if (response.data.dsSICUDsic_ocr_con.eeEstados[0].Returnid === 0) {
              commit('SET_STATE', state.states.success);
              resolve();
            } else {
              const notification = {
                code: response.data.dsSICUDsic_ocr_con.eeEstados[0].Returnid,
                message: response.data.dsSICUDsic_ocr_con.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              commit('SET_ERROR', notification);
              commit('SET_STATE', state.states.error);
              reject();
            }
          })
          .catch((error) => {
            const notification = {
              code: error.code || undefined,
              message: error.message,
              timestamp: Date.now(),
              type: 'error',
            };
            commit('SET_ERROR', notification);
            commit('SET_STATE', state.states.error);
            reject();
          });
      });
    },
  },
};
